<template>
  <div class="meeting-detail">
    <h3>会议详情</h3>
    <h4>会议信息</h4>
    <p>直播名称：{{detailInfo.programTitle}}</p>
    <p>QC号：{{detailInfo.uniqueQcId}}</p>
    <p>产品：{{detailInfo.brandBroaden}}</p>
    <p>类型：{{detailInfo.merckMeetingType}}</p>
    <p>形式：{{detailInfo.locationCity}}</p>
    <p>地点：{{detailInfo.merckMeetingType}}</p>
    <p>日期：{{detailInfo.startDate}}-{{detailInfo.endDate}}</p>
    <h4>会议讲者</h4>
    <p><a v-if="detailInfo.hcpList.length"><img style="width: 1rem;height: 1rem;border-radius: 0.5rem;" :src="detailInfo.hcpList[0].hcpPhoto"/> 姓名：{{detailInfo.hcpList[0].hcpName}}</a></p>
    <h4>会议简介：</h4>
    <p>{{detailInfo.purposeAndDescriptionOfTheProgram}}</p>
    <h4>相关推荐：</h4>
    <p>暂无</p>
  </div>
</template>
<script>
import indexApi from '@/api/index'
export default {
  data() {
    return {
        params:{
            id: this.$route.query.id,
            uniqueQcId:this.$route.query.uniqueQcId
        },
        detailInfo:{
            hcpList:[]
        },
    };
  },
  created(){
       console.log('沙克断送帝豪i山东i啊是');
       this.getMeeting();
  },
  mounted() {
     
      
  },
  methods: {
    getMeeting() {
        console.log(324234)
      let that = this;
      indexApi.getMeeting(this.params, "hcp").then(
        res => {
          // eslint-disable-next-line no-empty
          if (res && res.code === 200) {
              res.object.startDate = that.$tools.formatDated(res.object.startDate);
             res.object.endDate =  that.$tools.formatDated(res.object.endDate );
             that.detailInfo = res.object;
             
             
          }
        },
        err => {
          this.$message.success(
            (err && err.message) || "获取失败，请联系管理员！"
          );
        }
      );
    }
  }
};
</script>
<style lang="scss" scoped>
</style>